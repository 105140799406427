import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Loading, AuthRoute } from 'components';
import { APP_PREFIX_PATH } from 'configs';

export const AppViews = () => (
  <Suspense fallback={<Loading cover="content" />}>
    <Switch>
      <AuthRoute
        path={`${APP_PREFIX_PATH}/home`}
        roles={['Admin', 'Owner', 'Superadmin', 'Cashier']}
        component={lazy(() => import(`./home/Home`))}
      />

      <AuthRoute
        path={`${APP_PREFIX_PATH}/commerce`}
        roles={['Owner']}
        exact
        component={lazy(() => import(`./commerce/ListCommerce`))}
      />

      <AuthRoute
        path={`${APP_PREFIX_PATH}/commerce/view/:id`}
        exact
        roles={['Owner']}
        component={lazy(() => import(`./commerce/ViewCommerce`))}
      />

      <AuthRoute
        path={`${APP_PREFIX_PATH}/commerce/edit/:id`}
        exact
        roles={['Owner']}
        component={lazy(() => import(`./commerce/CreateCommerce`))}
      />

      <AuthRoute
        exact
        path={`${APP_PREFIX_PATH}/commerce/create`}
        roles={['Owner']}
        component={lazy(() => import(`./commerce/CreateCommerce`))}
      />

      <AuthRoute
        path={`${APP_PREFIX_PATH}/users/admins`}
        exact
        roles={['Owner']}
        component={lazy(() => import(`./users/ListUsers`))}
      />

      <AuthRoute
        path={`${APP_PREFIX_PATH}/users/atms`}
        roles={['Owner']}
        exact
        component={lazy(() => import(`./users/ListUsers`))}
      />

      <AuthRoute
        path={`${APP_PREFIX_PATH}/users/users`}
        roles={['Admin', 'Superadmin']}
        exact
        component={lazy(() => import(`./users/ListUsers`))}
      />

      <AuthRoute
        path={`${APP_PREFIX_PATH}/users/users/create`}
        roles={['Admin', 'Superadmin']}
        exact
        component={lazy(() => import(`./users/CreateUsers`))}
      />

      <AuthRoute
        path={`${APP_PREFIX_PATH}/audit`}
        roles={['Admin', 'Owner', 'Superadmin']}
        exact
        component={lazy(() => import(`./audit/ListAudit`))}
      />
      <AuthRoute
        path={`${APP_PREFIX_PATH}/users/users/edit/:id`}
        roles={['Admin', 'Superadmin']}
        exact
        component={lazy(() => import(`./users/CreateUsers`))}
      />
      <AuthRoute
        path={`${APP_PREFIX_PATH}/transactions`}
        roles={['Admin', 'Owner', 'Superadmin', 'Cashier']}
        exact
        component={lazy(() => import(`./transactions/ListTransactions`))}
      />
      <AuthRoute
        path={`${APP_PREFIX_PATH}/config`}
        roles={['Admin', 'Owner', 'Superadmin']}
        exact
        component={lazy(() => import(`./config/config`))}
      />

      <AuthRoute
        path={`${APP_PREFIX_PATH}/devices`}
        roles={['Admin', 'Superadmin']}
        exact
        component={lazy(() => import(`./devices/ListDevices`))}
      />

      <AuthRoute
        path={`${APP_PREFIX_PATH}/createTx`}
        roles={['Admin', 'Superadmin', 'Cashier']}
        exact
        component={lazy(() => import(`./createTransaction/CreateTransaction`))}
      />
      <AuthRoute
        path={`${APP_PREFIX_PATH}/close`}
        roles={['Admin', 'Superadmin', 'Cashier']}
        exact
        component={lazy(() => import(`./close/Close`))}
      />

      <AuthRoute
        path={`${APP_PREFIX_PATH}/help`}
        roles={['Admin', 'Superadmin']}
        exact
        component={lazy(() => import(`./help/Help`))}
      />

      <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
    </Switch>
  </Suspense>
);

export default React.memo(AppViews);
