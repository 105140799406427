import { Controller } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Box,
} from '@mui/material';

const SelectMultiple = ({
  name, // Nombre del campo
  label = '', // label del campo
  className = '', // estilos para la caja de el input
  control, // control proveniente de react-hook-form
  validations = {}, // Objeto con las validaciones
  items = [], // Items para el listado
  disabled = false, // Marcar campo deshabilitado
  fullWidth = false, // Si ocupa o no toda la caja donde se encuentra
  placeholder = 'Seleccione...', // Placeholder
  required = false, // Marcar campo como requerido
  defaultValue = [], // Valor por defecto
  variant = 'outlined', // Variante del input
  inputProps = {}, // Propiedades del input
  classes = {}, // Propiedades del input de MUI
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    rules={validations}
    defaultValue={defaultValue}
    render={({ field, fieldState }) => (
      <Box className={className} width={fullWidth ? '100%' : 'auto'}>
        <FormControl
          fullWidth={fullWidth}
          required={required}
          onChange={({ target: { value } }) => {
            field.onChange(value);
          }}
          error={fieldState.invalid}
          variant={variant}
          {...props}>
          {label !== '' ? <InputLabel id={name}>{label}</InputLabel> : null}
          <Select
            labelId={name}
            id={name}
            label={label}
            disabled={disabled}
            placeholder={placeholder}
            displayEmpty={label === ''}
            inputProps={inputProps}
            classes={classes}
            multiple
            {...field}
            value={field.value ? field.value : []}>
            {placeholder ? (
              <MenuItem disabled value="">
                <em>{placeholder}</em>
              </MenuItem>
            ) : null}

            {items.map((el, index) => (
              <MenuItem key={`${name}-${el.label}-${index}`} value={el.value}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {fieldState.invalid && (
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        )}
      </Box>
    )}
  />
);

export default SelectMultiple;
