import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  ({ spacing, palette, breakpoints }) => ({
    mainBox: {
      borderRadius: 27,
    },
    rootTitle: {
      margin: 0,
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > img': {
        width: 60,
        marginRight: spacing(2),
      },
    },
    content: {
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: 4,
        height: 4,
        borderRadius: 4,
        background: 'rgba(157, 177, 187, 0.5)',
      },
      '&::-webkit-scrollbar-thumb': {
        background: palette.primary.dark,
        borderRadius: 4,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: palette.primary.main,
      },
      [breakpoints.down('sm')]: {
        padding: spacing(1, 0),
      },
    },
    closeButton: {
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
      color: '#FFF',
      padding: 5,
      border: '2px solid #FFF',
      background: 'rgba(0, 0, 0, 0.42)',
    },
    rootActions: {
      justifyContent: 'center',
      padding: 15,
    },
  }),
  { index: 1 },
);

export default useStyles;
