import 'date-fns';
import { Controller } from 'react-hook-form';
import { TextField, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

const DatePickerForm = ({
  name, // Nombre del campo
  label = '', // label del campo
  className = '', // estilos para la caja de el input
  control, // control proveniente de react-hook-form
  validations = {}, // Objeto con las validaciones
  fullWidth = false, // Si ocupa o no toda la caja donde se encuentra
  required = false, // Marcar campo como requerido
  max = null, // fecha maxima
  min = null, // Fecha minima
  views = ['year', 'month', 'day'], // vista del datepicker
  variant = 'outlined', // Estilo del input de MUI
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={null}
    rules={validations}
    render={({ field, fieldState }) => (
      <DatePicker
        label={label}
        value={field.value}
        fullWidth={fullWidth}
        maxDate={max}
        minDate={min}
        error={fieldState.invalid}
        views={views}
        onChange={date => field.onChange(date)}
        {...field}
        renderInput={params => (
          <>
            <TextField
              {...params}
              fullWidth={fullWidth}
              required={required}
              className={className}
              error={fieldState.invalid}
              inputRef={params.inputRef}
              ref={params.ref}
              variant={variant}
            />
            {fieldState.invalid && (
              <FormHelperText>{fieldState.error?.message}</FormHelperText>
            )}
          </>
        )}
      />
    )}
  />
);

export default DatePickerForm;
