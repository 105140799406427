import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    buttonBox: {
      display: 'flex',
      margin: '10px',
      marginTop: '18px',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        '& button': {
          margin: '6px 0px',
        },
      },
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    backBtn: {
      marginRight: '10px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '6px',
      },
    },
    boxDataDetail: {
      border: '1px solid #888',
      boxShadow: theme.shadows[2],
      borderRadius: '12px',
      padding: '25px 20px',
      marginRight: '10px',
      [theme.breakpoints.down('sm')]: {
        padding: '15px 10px',
      },
    },
    dataTitle: {
      margin: '0px 5px !important',
      marginTop: '12px !important',
    },
    dataText: {
      marginTop: '5px !important',
    },
  }),
  { index: 1 },
);

export default useStyles;
