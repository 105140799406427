import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    optionIcon: {
      marginRight: '5px',
    },
    subHeaderBox: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '1200px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    filtersBox: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      },
    },
    inputStyle: {
      '& fieldset legend': {
        width: 'unset',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '10px 0px',
      },
    },
    buttonStyle: {
      [theme.breakpoints.down('sm')]: {
        margin: '6px 0px',
      },
    },
    tableBox: {
      border: '1px solid #666',
      borderRadius: '8px',
    },
  }),
  { index: 1 },
);

export default useStyles;
