import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    buttonFilter: {
      margin: '5px 8px 5px 0px',
      background: '#EEE',
      padding: '6px 0px',
      width: '110px',
      color: '#000',
      borderRadius: '18px',
    },
    buttonSelected: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
      '&:hover': {
        opacity: 0.8,
        backgroundColor: theme.palette.primary.main,
      },
    },
    boxStadistic: {
      border: '1px solid #D3D2D2',
      margin: '15px 0px',
      borderRadius: '8px',
    },
    mainStats: {
      flexBasis: '72.4% !important',
      maxWidth: '72.4% !important',
      [theme.breakpoints.down('md')]: {
        flexBasis: '100% !important',
        maxWidth: '100% !important',
      },
    },
    rentangleBoxStats: {
      flexBasis: '25% !important',
      maxWidth: '25% !important',
      [theme.breakpoints.down('md')]: {
        flexBasis: '100% !important',
        maxWidth: '100% !important',
      },
    },
    totalBoxStatsCashier: {
      flexBasis: '72.4% !important',
      maxWidth: '72.4% !important',
      [theme.breakpoints.down('md')]: {
        flexBasis: '100% !important',
        maxWidth: '100% !important',
      },
    },
    totalBoxStats: {
      flexBasis: '45% !important',
      maxWidth: '45% !important',
      [theme.breakpoints.down('md')]: {
        flexBasis: '100% !important',
        maxWidth: '100% !important',
      },
    },
    totalsBoxStadistic: {
      flexBasis: '47% !important',
      maxWidth: '47% !important',
      [theme.breakpoints.down('md')]: {
        flexBasis: '100% !important',
        maxWidth: '100% !important',
      },
    },
    boxDetailStadistic: {
      margin: '20px 20px 15px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'calc(100% - 35px)',
      [theme.breakpoints.down('lg')]: {
        margin: '15px 10px 10px 10px',
        height: 'calc(100% - 25px)',
      },
      [theme.breakpoints.down('md')]: {
        margin: '20px 20px 15px 20px',
        height: 'calc(100% - 35px)',
      },
    },
    titleStats: {
      fontWeight: '600',
      marginBottom: '18px',
      color: '#394154',
    },
    titleDateToday: {
      fontWeight: '500',
      marginBottom: '18px',
      color: '#757575',
    },
    subTitleTotals: {
      marginTop: '5px !important',
      color: '#394154',
    },
    quantityTotals: {
      fontWeight: '800',
      marginRight: '8px',
    },
    totalOperations: {
      color: '#B42025',
    },
    totalOperationsApproved: {
      color: '#EA345F',
    },
    totalOperationesCancel: {
      color: '#FAAB1E',
    },
    totalOperationsAverage: {
      color: '#407BFF',
    },
    signTotal: {
      margin: '0px 4px',
    },
    highTotal: {
      color: '#7BC043',
    },
    downTotal: {
      color: '#EA345F',
    },
    currencyIcom: {
      width: '20px',
      height: 'auto',
      marginRight: '12px',
    },
    labelCurrency: {
      lineHeight: '1',
      fontWeight: '300',
      marginRight: '12% !important',
    },
    rateCurrency: {
      lineHeight: '1',
      color: '#757575 !important',
      fontWeight: '500',
    },
    operationKindTextBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '15px 0px 0px',
    },
    operationKindIconTxt: {
      display: 'flex',
      alignItems: 'center',
    },
    operationKindIcon: {
      width: '20px',
      height: 'auto',
      marginRight: '8px',
    },
    dotCommerceList: {
      width: '20px',
      height: '20px',
      borderRadius: '199999px',
      marginRight: '8px',
    },
    filterDates: {
      marginTop: '10px',
      marginRight: '12px',
    },
    invisibleText: {
      color: '#FFF !important',
    },
  }),
  { index: 1 },
);

export default useStyles;
