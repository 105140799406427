import { DashboardOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH } from './AppConfig';

const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'home',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'transactions',
    path: `${APP_PREFIX_PATH}/transactions`,
    title: 'Transactions',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'close',
    path: `${APP_PREFIX_PATH}/close/Close`,
    title: 'Close',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'devices',
    path: `${APP_PREFIX_PATH}/devices`,
    title: 'Devices',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'commerce',
    path: `${APP_PREFIX_PATH}/commerce`,
    title: 'Commerce',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'commerceCreate',
    path: `${APP_PREFIX_PATH}/commerce/create`,
    title: 'Create Commerce',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'commerceEdit',
    path: `${APP_PREFIX_PATH}/commerce/edit/:id`,
    title: 'Edit Commerce',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'ListAudit',
    path: `${APP_PREFIX_PATH}/audit/ListAudit`,
    title: 'Audit',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'Config',
    path: `${APP_PREFIX_PATH}/config/config`,
    title: 'config',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
