import HomeIcon from '@mui/icons-material/Home';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import SettingsIcon from '@mui/icons-material/Settings';
import PaidIcon from '@mui/icons-material/Paid';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import PersonIcon from '@mui/icons-material/Person';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import HelpIcon from '@mui/icons-material/Help';

const HOME_ITEM = {
  title: 'Inicio',
  path: 'home',
  Icon: () => <HomeIcon />,
};

export const OWNER_SIDEBAR = [
  HOME_ITEM,
  {
    title: 'Comercio',
    path: 'commerce',
    Icon: () => <LocalGroceryStoreIcon />,
  },
  {
    title: 'Administradores',
    path: 'users/admins',
    Icon: () => <PersonIcon />,
  },
  {
    title: 'Cajeros',
    path: 'users/atms',
    Icon: () => <LocalAtmIcon />,
  },
  {
    title: 'Transacciones',
    path: 'transactions',
    Icon: () => <ReceiptLongIcon />,
  },
  {
    title: 'Configuración',
    path: 'config',
    Icon: () => <SettingsIcon />,
  },
  {
    title: 'Auditoria',
    path: 'audit',
    Icon: () => <ContentPasteSearchIcon />,
  },
];

export const ADMIN_SIDEBAR = [
  HOME_ITEM,
  {
    title: 'Dar Vueltos',
    path: 'createTx',
    Icon: () => <PaidIcon />,
  },
  {
    title: 'Usuarios',
    path: 'users/users',
    Icon: () => <PersonIcon />,
  },
  {
    title: 'Transacciones',
    path: 'transactions',
    Icon: () => <ReceiptLongIcon />,
  },
  {
    title: 'Cierre',
    path: 'close',
    Icon: () => <PointOfSaleIcon />,
  },
  {
    title: 'Dispositivos',
    path: 'devices',
    Icon: () => <TabletAndroidIcon />,
  },
  {
    title: 'Configuración',
    path: 'config',
    Icon: () => <SettingsIcon />,
  },
  {
    title: 'Auditoria',
    path: 'audit',
    Icon: () => <ContentPasteSearchIcon />,
  },
  {
    title: 'Ayuda',
    path: 'help',
    Icon: () => <HelpIcon />,
  },
];

export const USER_SIDEBAR = [
  HOME_ITEM,
  {
    title: 'Dar Vueltos',
    path: 'createTx',
    Icon: () => <PaidIcon />,
  },
  {
    title: 'Transacciones',
    path: 'transactions',
    Icon: () => <ReceiptLongIcon />,
  },
  {
    title: 'Cierre',
    path: 'close',
    Icon: () => <PointOfSaleIcon />,
  },
];
