const dev = {
  API_ENDPOINT_URL: 'https://api.dev.tuvueltoya.3dlinkweb.com/api',
};

const prod = {
  API_ENDPOINT_URL: 'https://api.vueltoya.bestpay.com.ve/api',
};

const test = {
  API_ENDPOINT_URL: 'https://api.qa.tuvueltoya.3dlinkweb.com/api',
};

const getEnvApi = () => {
  // eslint-disable-next-line no-undef
  console.log(process.env.REACT_APP_NODE_ENV);
  // eslint-disable-next-line no-undef
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnvApi();
