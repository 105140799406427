import { APP_NAME, YEAR_FOOTER } from 'configs';

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${YEAR_FOOTER}`}{' '}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights
        reserved.
      </span>
    </footer>
  );
}
