import axios from 'axios';
import { API_BASE_URL } from 'configs';

/* Manejo para todas las respuestas */
const responseHandler = response => {
  const { data, status } = response;

  if (status === 200) {
    return data;
  }

  return Promise.reject({ data, status });
};

// objeto para la instancia
const API = {};
// URL del servidor, manejo de ENV para diferentes servidores
const baseURL = `${API_BASE_URL}/`;

// Instancias
const axiosInstance = axios.create({
  baseURL,
  validateStatus() {
    return true;
  },
});

// Interceptos o proceso antes de la peticion
// Este permite que modifiques la peticion justo antes de enviarse
axiosInstance.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json';
    const token = localStorage.getItem('sessionid');
    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

// este es el interceptos de la respuesta
// Si falla podemos capturar aqui mismo el error si es generico como el de internet o un error 500 del servidor
axiosInstance.interceptors.response.use(responseHandler, error => {
  let msg = 'Error Interno';

  if (error.toJSON().message === 'Network Error') {
    msg = 'Error de conexión';
  }
  return Promise.reject({ data: { msg }, status: 504 });
});

// Y todo aqui para abajo, es declarar las peticiones
// Las insertamos en API que es nuestra instancia y listo

/* Hay varias formas puedes colocarle el return o no, depende alli del gusto
 asi mantengo las rutas de la api en el mismo sitio y las voy consumiendo en medida que las use
 En los GET, suelo es usar un objeto y le saco los valores,
 Por que aveces el orden de los parametros puede chocar, pero esto es al gusto tambien */
// AUTH
API.login = params => axiosInstance.post(`auth/login`, params);
API.logout = () => axiosInstance.post(`auth/logout`);
API.validateOtp = params => axiosInstance.post(`auth/login2fa`, params);
API.recover = params => axiosInstance.post('users/password_recovery', params);
API.validateOtpRecover = params =>
  axiosInstance.post('users/password_recovery_otp', params);
API.changePassword = (params, headers) =>
  axiosInstance.put('users/update_password', params, {
    headers,
  });

// Commerce
API.listCommerce = ({
  page,
  size = 10,
  sortBy = '',
  sortDirection = '',
  filter = '',
}) =>
  axiosInstance.get(
    `commerces?page=${page}&size=${size}${sortBy ? `&sortBy=${sortBy}` : ''}${
      sortDirection ? `&sortDirection=${sortDirection}` : ''
    }${filter ? `&filter=${filter}` : ''}`,
  );
API.createCommerce = params => axiosInstance.post('commerces', params);
API.editCommerce = ({ id, params }) =>
  axiosInstance.put(`commerces/${id}`, params);
API.getCommerceById = id => axiosInstance.get(`commerces/${id}`);
API.desactiveCommerceById = id =>
  axiosInstance.put(`commerces/activate_inactivate/${id}`);

// Roles
API.getRolesList = () => axiosInstance.get('/users/roles');

// Users
API.createUser = params => axiosInstance.post('users', params);
API.editUser = ({ id, params }) => axiosInstance.put(`users/${id}`, params);
API.getUserById = id => axiosInstance.get(`users/${id}`);
API.deleteUserById = id => axiosInstance.delete(`users/${id}`);
API.getUserList = ({
  page,
  size = 10,
  sortBy = '',
  sortDirection = '',
  filter = '',
  cashiers = null,
}) =>
  axiosInstance.get(
    `users?page=${page}&size=${size}${sortBy ? `&sortBy=${sortBy}` : ''}${
      sortDirection ? `&sortDirection=${sortDirection}` : ''
    }${filter ? `&filter=${filter}` : ''}${
      cashiers === null ? '' : `&cashiers=${cashiers}`
    }`,
  );
API.logoutUser = (userid, params) =>
  axiosInstance.put(`users/logout/${userid}`, params);
API.takeControlUser = id => axiosInstance.post(`users/take_control/${id}`);
API.getUserConfigById = id => axiosInstance.get(`configs/limits/${id}`);
API.saveConfigById = (id, params) =>
  axiosInstance.post(`configs/limits/${id}`, params);
API.updateConfigById = (id, params) =>
  axiosInstance.put(`configs/limits/${id}`, params);
/*
  API.updateConfigById = (id, params) =>
  axiosInstance.put(`configs/limits/${id}`, params);*/

// Configs
API.getPhoneCode = () => axiosInstance.get('configs/oper_types');
API.getRifType = () => axiosInstance.get('configs/rif_types');
API.getBcvRate = () => axiosInstance.get('configs/bcv');
API.setBcvRate = params => axiosInstance.put('configs/bcv', params);
API.getConfigGlobal = () => axiosInstance.get('configs/limits');

// Banks
API.getActiveBanks = () => axiosInstance.get(`banks/list`);
API.getBanksList = ({
  page = 0,
  limit = 10,
  sortBy = '',
  sortDirection = '',
  filter = '',
}) =>
  axiosInstance.get(
    `banks?page=${page}&size=${limit}&sortBy=${sortBy}&sortDirection=${sortDirection}&filter=${filter}`,
  );

// Devices
API.getDeviceList = ({
  offset,
  limit = 10,
  sortBy = '',
  sortDirection = 'asc',
  filter = '',
}) =>
  axiosInstance.get(
    `commerces/devices?limit=${limit}&page=${offset}&sortBy=${sortBy}&sortDirection=${sortDirection}&filter=${filter}`,
  );

//Audit
API.getAuditList = ({
  page,
  limit,
  sortBy = '',
  sortDirection = '',
  filter = '',
  dateEnd = '',
  dateStart = '',
  module = '',
}) =>
  axiosInstance.get(
    `logs?page=${page}&size=${limit}${sortBy ? `&sortBy=${sortBy}` : ''}${
      sortDirection ? `&sortDirection=${sortDirection}` : ''
    }${filter ? `&filter=${filter}` : ''}${
      dateEnd ? `&date_end=${dateEnd}` : ''
    }${module ? `&module=${module}` : ''}${
      dateStart ? `&date_init=${dateStart}` : ''
    }`,
  );
API.getAuditModules = () => axiosInstance.get('logs/filters');

// Transactions
API.getTransactionsList = ({
  page,
  limit,
  sortBy = '',
  sortDirection = '',
  filter = '',
  month = '',
}) =>
  axiosInstance.get(
    `transactions?page=${page}&size=${limit}&sortBy=${sortBy}&sortDirection=${sortDirection}&filter=${filter}${
      month ? `&month=${month}` : ''
    }`,
  );
API.configLimitGlobal = params => axiosInstance.post(`configs/limits`, params);
API.getTransactionDetail = id => axiosInstance.get(`transactions/${id}`);
API.createTransaction = params => axiosInstance.post('transactions', params);

// Dashboard
API.getTransactionsByStatus = ({
  type = 'day',
  init = '',
  end = '',
  userid = '',
}) =>
  axiosInstance.get(
    `dashboard/status?type=${type}&init=${init}&end=${end}&id=${userid}`,
  );
API.getTransactionsByType = ({
  type = 'day',
  init = '',
  end = '',
  userid = '',
}) =>
  axiosInstance.get(
    `dashboard/transaction_type?type=${type}&init=${init}&end=${end}&id=${userid}`,
  );
API.getTransactionsByCurrency = ({
  type = 'day',
  init = '',
  end = '',
  userid = '',
}) =>
  axiosInstance.get(
    `dashboard/currency?type=${type}&init=${init}&end=${end}&id=${userid}`,
  );
API.getTransactionsChart = ({
  type = 'day',
  init = '',
  end = '',
  userid = '',
}) =>
  axiosInstance.get(
    `dashboard?type=${type}&init=${init}&end=${end}&id=${userid}`,
  );
API.getTransactionsTopCommerces = ({ type = 'day', init = '', end = '' }) =>
  axiosInstance.get(
    `dashboard/top_commerces?type=${type}&init=${init}&end=${end}`,
  );
API.getTransactionsTopCashier = ({
  type = 'day',
  init = '',
  end = '',
  id = '',
}) =>
  axiosInstance.get(
    `dashboard/top_cashiers?type=${type}&init=${init}&end=${end}&commerce_id=${id}`,
  );

// Cierres
API.getListCashierRegister = ({
  page,
  filter = '',
  size = 10,
  sortBy = '',
  sortDirection = '',
}) =>
  axiosInstance.get(
    `cash_registers?page=${page}&size=${size}${
      sortBy ? `&sortBy=${sortBy}` : ''
    }${
      sortDirection ? `&sortDirection=${sortDirection}` : ''
    }&filter=${filter}`,
  );

API.getDetailCashier = id => axiosInstance.get(`cash_registers/${id}`);
API.putCloseCashier = id => axiosInstance.put(`cash_registers/close/${id}`);

// Notificaciones
API.getNotificationList = ({
  page,
  size = 10,
  sortBy = '',
  sortDirection = '',
  filter = '',
}) =>
  axiosInstance.get(
    `messaging?page=${page}&size=${size}&sortBy=${sortBy}&sortDirection=${sortDirection}&filter=${filter}`,
  );
API.createMessage = params => axiosInstance.post(`messaging`, params);

// Cuentas
API.getAccountByCommerce = () => axiosInstance.get('commerces/accounts');
API.getAccount = () => axiosInstance.get('commerces/accounts/active');
API.createAccount = params => axiosInstance.post('commerces/accounts', params);
API.getInfoBankCommerce = ({
  page,
  size = 10,
  sortBy = '',
  sortDirection = '',
  filter = '',
}) =>
  axiosInstance.get(
    `commerces/accounts/list_all?page=${page}&size=${size}${
      sortBy ? `&sortBy=${sortBy}` : ''
    }${sortDirection ? `&sortDirection=${sortDirection}` : ''}${
      filter ? `&filter=` : ''
    }`,
  );

export default API;
