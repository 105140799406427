import { Redirect, Route } from 'react-router-dom';
import { APP_PREFIX_PATH } from 'configs';

const LoginRoute = props => {
  const { component: Component, ...rest } = props;
  const isNotAuthenticated = localStorage.getItem('sessionid');

  return (
    <Route
      {...rest}
      render={routeProps =>
        !isNotAuthenticated ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={APP_PREFIX_PATH} />
        )
      }
    />
  );
};

export default LoginRoute;
