import { useState, useEffect } from 'react';
import { Drawer, Grid } from 'antd';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import Logo from './Logo';
import { onMobileNavToggle } from 'redux/actions/Theme';
import { Flex } from 'components';
import { APP_PREFIX_PATH } from 'configs';
import { getSidebarByRole } from 'utils';

const { useBreakpoint } = Grid;

export const MobileNav = ({ mobileNav, onMobileNavToggle }) => {
  const [sidebarShow, setSidebarShow] = useState([]);

  // Others
  const history = useHistory();
  const screens = useBreakpoint();

  useEffect(() => {
    setSidebarShow(getSidebarByRole());
  }, []);

  const onClose = () => {
    onMobileNavToggle(false);
  };

  return (
    <>
      {mobileNav ? (
        <Drawer
          width={screens.xs ? '100%' : '378px'}
          placement="left"
          closable={false}
          onClose={onClose}
          visible={mobileNav}
          bodyStyle={{ padding: 5 }}>
          <Flex flexDirection="column" className="h-100">
            <Flex justifyContent="between" alignItems="center">
              <Logo mobileLogo={true} />
              <div className="nav-close" onClick={() => onClose()}>
                <ArrowLeftOutlined />
              </div>
            </Flex>
            <div className="mobile-nav-menu">
              <Scrollbars autoHide>
                <List>
                  {sidebarShow.map(({ Icon, ...el }) => (
                    <ListItem disablePadding key={el.title}>
                      <ListItemButton
                        id={`sidebarBtn-${el.title}`}
                        onClick={() =>
                          history.push(`${APP_PREFIX_PATH}/${el.path}`)
                        }>
                        {Icon ? (
                          <ListItemIcon>
                            <Icon />
                          </ListItemIcon>
                        ) : null}
                        <ListItemText primary={el.title} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Scrollbars>
            </div>
          </Flex>
        </Drawer>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme, mobileNav } = theme;
  return { navCollapsed, sideNavTheme, mobileNav };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MobileNav);
