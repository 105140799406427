import { Redirect, Route } from 'react-router-dom';

const AuthRoute = props => {
  const { component: Component, roles, ...rest } = props;
  const isAuthenticated = localStorage.getItem('sessionid');
  const roleStorage = localStorage.getItem('role');

  return (
    <Route
      {...rest}
      render={routeProps =>
        isAuthenticated ? (
          roles.includes(roleStorage) ? (
            <Component {...routeProps} />
          ) : (
            <Redirect to="/app/home" />
          )
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};

export default AuthRoute;
