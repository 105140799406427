import { makeStyles } from '@mui/styles';
import { stepConnectorClasses } from '@mui/material/StepConnector';

const useStyles = makeStyles(
  theme => ({
    iconColor: {
      border: '2px solid #14427C',
      color: '#14427C',
      padding: '5px 10px',
      borderRadius: '10000px',
    },
    iconDisabled: {
      border: '2px solid #A7A7A7',
      color: '#A7A7A7',
    },
    iconCompleted: {
      backgroundColor: '#14427C',
      color: '#FFF',
    },
    formBox: {
      maxWidth: '400px',
      margin: 'auto',
      '@media print': {
        maxWidth: 'initial',
      },
    },
    labelInput: {
      color: '#000',
      marginBottom: '3px',
      userSelect: 'none',
      fontSize: '16px',
    },
    inputForm: {
      '& input': {
        textAlign: 'right',
      },
    },
    inputLabelBox: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: '15px',
    },
    inputRowBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    inputInCurrencies: {
      width: '70%',
    },
    inputEurBox: {
      marginTop: '15px',
    },
    symbolBox: {
      width: '25%',
      border: ' 1px solid #BBBBBB',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    currencyCashBox: {
      width: '25%',
    },
    imgSymbol: {
      height: '35px',
      margin: '0px 10px',
    },
    buttonForm: {
      paddingRight: 0,
      paddingLeft: 0,
      marginTop: '25px',
      '@media print': {
        display: 'none',
      },
    },
    connectorBox: {
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 15,
      },
      [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundColor: '#14427C',
        },
      },
      [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundColor: '#14427C',
        },
      },
      [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
      },
    },
    labelInputBig: {
      color: '#000',
      marginBottom: '8px',
      userSelect: 'none',
      fontSize: '18px',
      textAlign: 'center',
    },
    inputLabelBigBox: {
      marginTop: '25px',
    },
    inputFormTextCenter: {
      '& input': {
        textAlign: 'center',
      },
    },
    inputRateText: {
      '& input': {
        color: '#14427C',
        fontSize: '24px',
        fontWeight: '700',
        padding: '10.76px 0px',
      },
    },
    inputBigText: {
      '& input': {
        color: '#000',
        fontSize: '24px',
        fontWeight: '700',
        padding: '10.76px 0px',
      },
    },
    resumeTextBox: {
      marginBottom: '18px',
    },
    textTitleResume: {
      color: '#000',
      marginBottom: '8px',
      userSelect: 'none',
      textAlign: 'center',
    },
    textContentResume: {
      color: '#000',
      textAlign: 'center',
    },
    successIconTitle: {
      width: '40px',
      height: 'auto',
      marginRight: '12px',
    },
    labelValueBox: {
      marginBottom: '8px',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '@media print': {
        display: 'block',
      },
    },
    successModalLabel: {
      color: '#000',
    },
  }),
  { index: 1 },
);

export default useStyles;
