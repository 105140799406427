import { useRef, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import axios from 'axios';

import Views from './views';
import store from './redux/store';
import { theme, config, AppContext } from 'utils';
import { THEME_CONFIG } from 'configs';

const themes = {
  // eslint-disable-next-line no-undef
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  // eslint-disable-next-line no-undef
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const App = () => {
  const snackbar = useRef();

  // state
  const [conf, setConf] = useState(config);

  // Funciones
  const saveIP = val => {
    setConf(prevState => ({
      ...prevState,
      ip: val,
    }));
  };

  useEffect(() => {
    getUserIP();
  }, []);

  const getUserIP = async () => {
    // Obtenemos la IP del usuario
    const res = await axios.get('https://geolocation-db.com/json/');
    saveIP(res.data.IPv4);
  };

  return (
    <div className="App">
      <AppContext.Provider value={{ ...conf, setIp: saveIP }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
          <Provider store={store}>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={THEME_CONFIG.currentTheme}
              insertionPoint="styles-insertion-point">
              <StyledEngineProvider injectFirst>
                <SnackbarProvider
                  ref={snackbar}
                  action={key => (
                    <IconButton
                      aria-label="close"
                      onClick={() => snackbar.current.closeSnackbar(key)}
                      sx={{
                        color: '#FFF',
                      }}>
                      <CloseIcon />
                    </IconButton>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}>
                  <ThemeProvider theme={theme}>
                    <Router>
                      <Switch>
                        <Route path="/" component={Views} />
                      </Switch>
                    </Router>
                  </ThemeProvider>
                </SnackbarProvider>
              </StyledEngineProvider>
            </ThemeSwitcherProvider>
          </Provider>
        </LocalizationProvider>
      </AppContext.Provider>
    </div>
  );
};

export default App;
