import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    titleBox: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    backBtn: {
      marginRight: '10px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '6px',
      },
    },
    inputSep: {
      margin: '10px',
    },
    buttonBox: {
      display: 'flex',
      margin: '10px',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        '& button': {
          margin: '6px 0px',
        },
      },
    },
  }),
  { index: 1 },
);

export default useStyles;
