import { Switch, Route } from 'react-router-dom';
import AuthViews from 'views/auth';

export const AuthLayout = () => (
  <div className="auth-container">
    <Switch>
      <Route path="" component={AuthViews} />
    </Switch>
  </div>
);

export default AuthLayout;
