import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConfigProvider } from 'antd';
import { AppLayout, AuthLayout } from 'components';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs';
import { useBodyClass } from 'utils';

export const Views = props => {
  const { location, direction } = props;
  useBodyClass(`dir-${direction}`);
  return (
    <ConfigProvider locale="es_ES" direction={direction}>
      <Switch>
        <Route exact path="/">
          <Redirect to={AUTH_PREFIX_PATH} />
        </Route>
        <Route path={AUTH_PREFIX_PATH}>
          <AuthLayout direction={direction} />
        </Route>
        <Route path={APP_PREFIX_PATH}>
          <AppLayout direction={direction} location={location} />
        </Route>
      </Switch>
    </ConfigProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { direction } = theme;
  const { token } = auth;
  return { token, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
