import { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { APP_PREFIX_PATH } from 'configs';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, getSidebarByRole } from 'utils';

const { Sider } = Layout;

export const SideNav = ({ navCollapsed, sideNavTheme }) => {
  // state
  const [sidebarShow, setSidebarShow] = useState([]);

  // Others
  const history = useHistory();

  useEffect(() => {
    setSidebarShow(getSidebarByRole());
  }, []);

  return (
    <Sider
      className={`side-nav ${
        sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''
      }`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}>
      <List>
        {sidebarShow.map(({ Icon, ...el }) => (
          <ListItem disablePadding key={el.title}>
            <ListItemButton
              id={`sidebarBtn-${el.title}`}
              sx={navCollapsed ? { justifyContent: 'center' } : {}}
              onClick={() => history.push(`${APP_PREFIX_PATH}/${el.path}`)}>
              {Icon ? (
                <ListItemIcon sx={navCollapsed ? { minWidth: 'initial' } : {}}>
                  <Icon />
                </ListItemIcon>
              ) : null}
              {navCollapsed ? null : <ListItemText primary={el.title} />}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Sider>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme } = theme;
  return { navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps)(SideNav);
