import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(
  theme => ({
    mainBox: {
      backgroundColor: '#14427C',
    },
    floatBox: {
      padding: '30px 50px',
      [theme.breakpoints.down('xl')]: {
        padding: '25px 45px',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '20px 40px',
      },
      [theme.breakpoints.down('md')]: {
        padding: '15px 35px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 25px',
      },
    },
    logoImg: {
      width: '80%',
    },
    titleBox: {
      marginTop: 12,
      color: theme.palette.primary.main,
    },
    formBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    marginButton: {
      marginTop: 16,
    },
    inputStyle: {
      marginTop: 10,
    },
    haveAccount: {
      userSelect: 'none',
      marginTop: '20px !important',
      color: theme.palette.primary.main,
      fontWeight: 'bold !important',
    },
    modalInput: {
      margin: '15px 0px',
      '& input': {
        textAlign: 'center',
        margin: '0px 8px !important',
        borderRadius: '6px',
        width: '50px',
        height: '50px',
        border: '1px solid #77777750',
        [theme.breakpoints.down('md')]: {
          margin: '0px 6px !important',
        },
        [theme.breakpoints.down('sm')]: {
          width: '30px',
          height: '30px',
          margin: '0px 4px !important',
        },
      },
    },
    modalOtpText: {
      color: '#555',
    },
  }),
  { index: 1 },
);

export default useStyles;
