import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    optionIcon: {
      marginRight: '5px',
    },
    subHeaderBox: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '1200px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    filtersBox: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      },
    },
    inputStyle: {
      alignItems: 'baseline',
      '& fieldset legend': {
        width: 'unset',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '10px 0px',
      },
    },
    buttonStyle: {
      margin: '10px',
      alignSelf: 'flex-end',
    },
    tableBox: {
      border: '1px solid #666',
      borderRadius: '8px',
    },
    Accordion: {
      display: 'block',
      textAlign: 'center',
      marginLeft: '20px',
      marginRight: '20px',
    },
    boxAcStyle: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '10px',
    },
    boxTypogra: {
      marginBottom: '10px !important',
    },
    boxAcStyleIn: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      marginRight: '16px !important',
      marginTop: '10px !important',
    },
    inputConfig: {
      width: '150px',
    },
    inputStyleConfig: {
      textAlign: 'center',
    },
    inputRate: {
      '& input': {
        textAlign: 'right',
      },
    },
    formBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    modalButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    modalMessageBox: {
      padding: '15px',
    },
    formBoxMsg: {
      margin: '18px 0px',
    },
    inputMsgSep: {
      margin: '10px 0px',
    },
    switchInputAcc: {
      '& .MuiSwitch-root': {
        width: '60px',
        height: '37px',
      },
    },
    phoneCodeSep: {
      paddingRight: '10px',
    },
  }),
  { index: 1 },
);

export default useStyles;
