import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  Switch,
  FormControlLabel,
  Box,
} from '@mui/material';

const SwitchForm = ({
  name, // Nombre del campo
  label, // label del campo
  className, // estilos para la caja de el input
  control, // control proveniente de react-hook-form
  validations = {}, // Objeto con las validaciones
  disabled = false, // Marcar campo deshabilitado
  fullWidth = false, // Si ocupa o no toda la caja donde se encuentra
  required = false, // Marcar campo como requerido
  defaultValue = '', // Valor por defecto
  variant = 'outlined', // Variante del input
  inputProps = {}, // Propiedades del input
  classes = {}, // Propiedades del input de MUI
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    rules={validations}
    defaultValue={defaultValue}
    render={({ field, fieldState }) => (
      <Box className={className} width={fullWidth ? '100%' : 'auto'}>
        <FormControl
          fullWidth={fullWidth}
          required={required}
          error={fieldState.invalid}
          variant={variant}
          {...props}>
          <FormControlLabel
            control={
              <Switch
                classes={classes}
                disabled={disabled}
                inputProps={inputProps}
                checked={field.value}
                {...field}
              />
            }
            label={label ? label : null}
          />
        </FormControl>
        {fieldState.invalid && (
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        )}
      </Box>
    )}
  />
);

export default SwitchForm;
