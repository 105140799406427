import { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import { connect } from 'react-redux';
import ThemeConfigurator from './ThemeConfigurator';
import { DIR_RTL } from 'utils';

const NavPanel = props => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item key="panel" onClick={showDrawer}>
          <span>
            <SettingOutlined className="nav-icon mr-0" />
          </span>
        </Menu.Item>
      </Menu>
      <Drawer
        title="Theme Config"
        placement={props.direction === DIR_RTL ? 'left' : 'right'}
        width={visible ? 350 : 0}
        onClose={onClose}
        visible={visible}>
        <ThemeConfigurator />
      </Drawer>
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(NavPanel);
