import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    optionIcon: {
      marginRight: '5px',
    },
    subHeaderBox: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '1200px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    filtersBox: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      },
    },
    inputStyle: {
      '& fieldset legend': {
        width: 'unset',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '10px 0px',
      },
    },
    buttonStyle: {
      [theme.breakpoints.down('sm')]: {
        margin: '6px 0px',
      },
    },
    tableBox: {
      border: '1px solid #666',
      borderRadius: '8px',
    },
    modalText: {
      marginBottom: '12px',
    },
    modalButtonBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '12px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    modalPdf: {
      textAlign: 'center',
      padding: '10px',
      [theme.breakpoints.down('sm')]: {
        margin: 10000,
      },
    },
    modalAlert: {
      color: '#FF7A00',
    },
    warning: {
      margin: 30,
    },
    typography: {
      textAlign: 'center',
    },
    modalButton: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 30,
    },
    modalButtonW: {
      width: '100px',
    },

    textModal: {
      alignItems: 'center',
    },
  }),
  { index: 1 },
);

export default useStyles;
