import { useLoadingFullScreenStyles } from 'styles';

const LoadingFullScreen = () => {
  const styles = useLoadingFullScreenStyles();

  return (
    <div className={styles.loadingBox}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default LoadingFullScreen;
