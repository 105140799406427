import { env } from './EnvironmentConfig';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'utils';

// General
export const APP_NAME = 'TuVueltoYa';
export const YEAR_FOOTER = '2022';

// Server
export const API_BASE_URL = env.API_ENDPOINT_URL;

// Rutas
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  direction: DIR_LTR,
};
