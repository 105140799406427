import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { useHistory } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import {
  IconButton,
  Popover,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSnackbar } from 'notistack';

import API from '../../utils/api';
import NavSearch from './NavSearch';
import Logo from './Logo';
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
  getColorContrast,
  getUserData,
} from 'utils';
import { LoadingFullScreen } from 'components';
import { AUTH_PREFIX_PATH } from 'configs';

const { Header } = Layout;

export const HeaderNav = props => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
  } = props;
  const [searchActive, setSearchActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff');
    }
    return getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    // Obtenemos los datos del usuario
    const userTmp = getUserData();
    setUser(userTmp);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  const logout = async () => {
    try {
      setLoading(true);

      await API.logout();
      localStorage.clear();
      history.push(`${AUTH_PREFIX_PATH}/login`);

      setLoading(false);
    } catch (error) {
      const {
        status,
        data: { data: msg },
      } = error;
      setLoading(false);
      switch (status) {
        case 401:
          enqueueSnackbar('Usuario cerró sesión', {
            variant: 'error',
          });
          localStorage.clear();
          history.push(`${AUTH_PREFIX_PATH}/login`);
          break;
        case 500:
          enqueueSnackbar('Error interno', {
            variant: 'error',
          });
          break;
        default:
          enqueueSnackbar(msg, {
            variant: 'error',
          });
          break;
      }
    }
  };

  return (
    <>
      <Header
        className={`app-header ${navMode}`}
        style={{ backgroundColor: headerNavColor }}>
        <div
          className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
          <Logo logoType={navMode} />
          <div
            className="nav"
            style={{ width: `calc(100% - ${getNavWidth()})` }}>
            <div className="nav-left">
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                {isNavTop && !isMobile ? null : (
                  <li
                    className="ant-menu-item ant-menu-item-only-child"
                    onClick={() => {
                      onToggle();
                    }}>
                    {navCollapsed || isMobile ? (
                      <MenuUnfoldOutlined className="nav-icon" />
                    ) : (
                      <MenuFoldOutlined className="nav-icon" />
                    )}
                  </li>
                )}
              </ul>
            </div>
            <div className="nav-right align-items-center">
              <IconButton aria-describedby="user-popover" onClick={handleClick}>
                <AccountCircleIcon />
              </IconButton>
              <Popover
                id="user-popover"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}>
                <List>
                  <ListItem>
                    <ListItemText secondary={user.username} />
                  </ListItem>
                  <ListItem
                    disablePadding
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="logout"
                        onClick={() => logout()}>
                        <LogoutIcon />
                      </IconButton>
                    }>
                    <ListItemButton onClick={() => logout()}>
                      <ListItemText primary="Salir" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </div>
            <NavSearch active={searchActive} close={onSearchClose} />
          </div>
        </div>
      </Header>
      {loading ? <LoadingFullScreen /> : null}
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
