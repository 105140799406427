/* eslint-disable no-useless-escape */
/* eslint-disable no-prototype-builtins */

import {
  USER_SIDEBAR,
  OWNER_SIDEBAR,
  ADMIN_SIDEBAR,
  AUTH_PREFIX_PATH,
} from 'configs';
/**
 * Get current path related object from Navigation Tree
 * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
 * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
 * @return {Object} object that contained the path string
 */
export const getRouteInfo = (navTree, path) => {
  if (navTree.path === path) {
    return navTree;
  }
  let route;
  for (const p in navTree) {
    if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object') {
      route = getRouteInfo(navTree[p], path);
      if (route) {
        return route;
      }
    }
  }
  return route;
};

/**
 * Get accessible color contrast
 * @param {String} hex - Hex color code e.g '#3e82f7'
 * @return {String} 'dark' or 'light'
 */
export const getColorContrast = hex => {
  if (!hex) {
    return 'dark';
  }
  const threshold = 130;
  const hRed = hexToR(hex);
  const hGreen = hexToG(hex);
  const hBlue = hexToB(hex);
  function hexToR(h) {
    return parseInt(cutHex(h).substring(0, 2), 16);
  }
  function hexToG(h) {
    return parseInt(cutHex(h).substring(2, 4), 16);
  }
  function hexToB(h) {
    return parseInt(cutHex(h).substring(4, 6), 16);
  }
  function cutHex(h) {
    return h.charAt(0) === '#' ? h.substring(1, 7) : h;
  }
  const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
  if (cBrightness > threshold) {
    return 'dark';
  } else {
    return 'light';
  }
};

/**
 * Get Breakpoint
 * @param {Object} screens - Grid.useBreakpoint() from antd
 * @return {Array} array of breakpoint size
 */
export const getBreakPoint = screens => {
  const breakpoints = [];
  for (const key in screens) {
    if (screens.hasOwnProperty(key)) {
      const element = screens[key];
      if (element) {
        breakpoints.push(key);
      }
    }
  }
  return breakpoints;
};

/**
 * Darken or lighten a hex color
 * @param {String} color - Hex color code e.g '#3e82f7'
 * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
 * @return {String} Darken or lighten color
 */
export const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);
  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);
  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;
  const RR =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
  return `#${RR}${GG}${BB}`;
};

/**
 * Convert RGBA to HEX
 * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
 * @return {String} HEX color
 */
export const rgbaToHex = rgba => {
  const trim = str => str.replace(/^\s+|\s+$/gm, '');
  const inParts = rgba.substring(rgba.indexOf('(')).split(','),
    r = parseInt(trim(inParts[0].substring(1)), 10),
    g = parseInt(trim(inParts[1]), 10),
    b = parseInt(trim(inParts[2]), 10),
    a = parseFloat(
      trim(inParts[3].substring(0, inParts[3].length - 1)),
    ).toFixed(2);
  const outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255)
      .toString(16)
      .substring(0, 2),
  ];

  outParts.forEach(function (part, i) {
    if (part.length === 1) {
      outParts[i] = '0' + part;
    }
  });
  return `#${outParts.join('')}`;
};

export const logout = async (history, logoutApi = () => {}) => {
  try {
    await logoutApi();
    localStorage.clear();
    history.push(`${AUTH_PREFIX_PATH}/login`);
  } catch (e) {
    localStorage.clear();
    history.push(`${AUTH_PREFIX_PATH}/login`);
  }
};

export const getUserData = () => JSON.parse(localStorage.getItem('user'));
export const getUserRole = () => localStorage.getItem('role');

export const getSidebarByRole = () => {
  const typeUser = getUserData().role.name;
  if (typeUser == 'Owner') {
    return OWNER_SIDEBAR;
  } else if (typeUser == 'Admin' || typeUser == 'Superadmin') {
    return ADMIN_SIDEBAR;
  } else if (typeUser == 'Cashier') {
    return USER_SIDEBAR;
  } else {
    return USER_SIDEBAR;
  }
};

/*
 * Obtener una fecha formateada
 * date: Fecha a formatear
 *  DD/MM/YYYY - HH:mm
 */
export const getFormattedDateAndHourEsp = date => {
  const dateFormatted = new Date(date);

  let hours = dateFormatted.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return `${
    dateFormatted.getDate() < 10
      ? `0${dateFormatted.getDate()}`
      : dateFormatted.getDate()
  }/${
    dateFormatted.getMonth() + 1 < 10
      ? `0${dateFormatted.getMonth() + 1}`
      : dateFormatted.getMonth() + 1
  }/${dateFormatted.getFullYear()} - ${hours < 10 ? `0${hours}` : hours}:${
    dateFormatted.getMinutes() < 10
      ? `0${dateFormatted.getMinutes()}`
      : dateFormatted.getMinutes()
  } ${ampm}`;
};

export const toNumber = val => {
  const formattedVal = val.replace(/\./g, '').replace(/\,/g, '.');

  return Number(formattedVal) === 0
    ? 0
    : Number(Number(formattedVal).toFixed(2));
};

export const commafy = num => {
  const str = num.toString().split(',');
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1.');
  }
  if (str[1] && str[1].length >= 2) {
    str[1] = str[1].replace(/(\d{3})/g, '$1');
  }
  return str.join(',');
};

// Funcion para formatear textos mientras se escriben
export const formatAmountCommaDecimals = (val, prevVal) => {
  if (val.length === 0 || (val.length === 1 && val == 0)) {
    return '';
  } else if (val.length === 1 || val.length === 2) {
    const num = val.replace(/[^0-9]/g, '');
    if (num) {
      return `,${num}`;
    } else {
      return '';
    }
  } else if (val.length === 3) {
    let num = val.replace(/[^0-9]/g, '');
    num = num.replace(/\,/g, '');
    return `,${num}`;
  } else if (val.length === 4) {
    let num = val.replace(/[^0-9]/g, '');
    num = num.replace(/\,/g, '');
    return `${num[0]},${num[1]}${num[2]}`;
  } else {
    if (val.length > prevVal.length) {
      let num = val.replace(/[^0-9]/g, '');
      num = num.replace(/\./g, '');

      num = `${num.toString().substring(0, num.length - 2)},${num
        .toString()
        .slice(-2)}`;
      return commafy(num);
    } else {
      let num = val.replace(/[^0-9]\,/g, '');
      num = num.replace(/\./g, '');
      num = num.toString().split(',');
      num = `${num[0].toString().slice(0, -1)},${num[0].toString().slice(-1)}${
        num[1]
      }`;
      return commafy(num);
    }
  }
};

export const getUserSO = () => {
  let OSName = 'Unknown';
  if (window.navigator.userAgent.indexOf('Windows NT 10.0') != -1) {
    OSName = 'Windows 10';
  }
  if (window.navigator.userAgent.indexOf('Windows NT 6.3') != -1) {
    OSName = 'Windows 8.1';
  }
  if (window.navigator.userAgent.indexOf('Windows NT 6.2') != -1) {
    OSName = 'Windows 8';
  }
  if (window.navigator.userAgent.indexOf('Windows NT 6.1') != -1) {
    OSName = 'Windows 7';
  }
  if (window.navigator.userAgent.indexOf('Windows NT 6.0') != -1) {
    OSName = 'Windows Vista';
  }
  if (window.navigator.userAgent.indexOf('Windows NT 5.1') != -1) {
    OSName = 'Windows XP';
  }
  if (window.navigator.userAgent.indexOf('Windows NT 5.0') != -1) {
    OSName = 'Windows 2000';
  }
  if (window.navigator.userAgent.indexOf('Mac') != -1) {
    OSName = 'Mac/iOS';
  }
  if (window.navigator.userAgent.indexOf('X11') != -1) {
    OSName = 'UNIX';
  }
  if (window.navigator.userAgent.indexOf('Linux') != -1) {
    OSName = 'Linux';
  }

  if (OSName === 'Unknown') {
    OSName = 'Unknown';
  }

  return OSName;
};

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
