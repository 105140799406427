import { connect } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { getColorContrast } from 'utils';

export const NavSearch = props => {
  const { active, close, headerNavColor } = props;
  const mode = getColorContrast(headerNavColor);

  return (
    <div
      className={`nav-search ${active ? 'nav-search-active' : ''} ${mode}`}
      style={{ backgroundColor: headerNavColor }}>
      <div className="nav-close" onClick={close}>
        <CloseOutlined />
      </div>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { headerNavColor } = theme;
  return { headerNavColor };
};

export default connect(mapStateToProps, {})(NavSearch);
