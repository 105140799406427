import { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Loading, LoginRoute } from 'components';
import { AUTH_PREFIX_PATH } from 'configs';

export const AppViews = () => (
  <Suspense fallback={<Loading cover="page" />}>
    <Switch>
      <LoginRoute
        path={`${AUTH_PREFIX_PATH}/login`}
        component={lazy(() => import(`./Login`))}
      />
      <LoginRoute
        path={`${AUTH_PREFIX_PATH}/forgot`}
        component={lazy(() => import(`./Forgot`))}
      />
      <LoginRoute
        path={`${AUTH_PREFIX_PATH}/change`}
        component={lazy(() => import(`./Change`))}
      />
      <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
    </Switch>
  </Suspense>
);

export default AppViews;
